export const DateService = {
  dateEliteFormat(date) {
    if (date !== null && date != undefined && date!== '') {
       let eliteDate = new Date(date)
      if (eliteDate != 'Invalid Date' || eliteDate != '') {
        const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(eliteDate);
        const mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(eliteDate);
        const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(eliteDate);
        return `${da}${mo}${ye}`;
      }
      return null;
    }
    return null;
  }
}
